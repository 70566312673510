const colorMapping = {
  tutorials: '#EA67C9',
  crochet: '#dd72ab',
  savoirFaire: '#949ee9',
  laRecup2: '#d792ac',
  couture: '#f67dea',
  broderie: '#f89fba',
  deco: '#c5adf1',
  tutos: '#a99278',
  trapilho: '#8b9e7e',
  vestimentaire: '#7f6fa5',
  amigurumi: '#ca6363',
  objetsUtilitaires: '#70989c',
  recettesInsolites: '#94c4e6',
  recolteSauvage: '#629270',
  trucsEtAstuces: '#559e79',
  points: '#d23131b8',
  // maison: '#d9d2f7',
}

export default Object.keys(colorMapping).reduce(
  (acc, tagSlug) => ({
    ...acc,
    [`&.${tagSlug}`]: {
      borderColor: colorMapping[tagSlug],
      backgroundColor: colorMapping[tagSlug],
      '&.outlined': {
        backgroundColor: '#FFF',
        color: colorMapping[tagSlug],
      },
    },
  }),
  {}
)
