import styled from 'styled-components'
import { breakpoints } from 'utils/breakpoints'

export const Wrapper = styled.div`
  display: block;

  @media ${breakpoints.md} {
    display: none;
  }
`
