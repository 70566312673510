import React from 'react'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import useScrollInfo from 'hooks/useScrollInfo'
import scrollToTop from 'utils/scrollToTop'
import * as S from './ScrollToTop.styles'

function ScrollToTop() {
  const { scrolledHeight } = useScrollInfo()

  const handleClick = () => scrollToTop(200)

  if (!(scrolledHeight > 200)) {
    return null
  }
  return (
    <S.IconBtn onClick={handleClick} aria-label="back-to-top">
      <ArrowUpwardIcon fontSize="large" />
    </S.IconBtn>
  )
}

export default ScrollToTop
