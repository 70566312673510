import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  font-family: Lora, serif;
  color: rgba(80, 80, 80, 1);

  & a {
    color: #eb71cc;
    font-weight: 700;
    text-decoration: none;
  }
`
