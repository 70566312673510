import styled from 'styled-components'
import { breakpoints } from 'utils/breakpoints'

export const Wrapper = styled.div`
  display: none;
  justify-content: center;
  margin: 0;
  width: 100%;
  max-width: 100%;

  @media ${breakpoints.md} {
    display: flex;
  }
`

export const Nav = styled.nav`
  position: asbolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 0 10px #c4c4c4;
`
