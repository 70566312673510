import React from 'react'
import T from 'prop-types'

import Logo from 'components/Logo'

import * as S from './HeaderDesktopMenuReduced.styles'

function HeaderDesktopMenuReduced({ logo }) {
  return (
    <S.Wrapper>
      <S.Nav>
        <Logo size="small" logo={logo} />
      </S.Nav>
    </S.Wrapper>
  )
}

HeaderDesktopMenuReduced.propTypes = {
  logo: T.shape({}),
}

export default HeaderDesktopMenuReduced
