import React from 'react'
import T from 'prop-types'

import Logo from 'components/Logo'
import * as S from './HeaderMobileMenu.styles'

function HeaderMobileMenu({ logo }) {
  return (
    <S.Wrapper>
      <Logo logo={logo} />
    </S.Wrapper>
  )
}

HeaderMobileMenu.propTypes = {
  logo: T.shape({}),
}

export default HeaderMobileMenu
