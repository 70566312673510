import styled from 'styled-components'
import { Link } from 'gatsby'

import { breakpoints } from 'utils/breakpoints'

export const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);

  @media ${breakpoints.md} {
    position: fixed;
    top: 40%;
    left: 8px;
    bottom: auto;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: auto;
    background-color: transparent;
    box-shadow: none;
  }
`

export const GastbyLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin: 4px;
  cursor: pointer;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media ${breakpoints.md} {
    margin: 8px;
    padding: 12px;
    background-color: #fff;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.2);
  }
`

export const ALink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin: 4px;
  cursor: pointer;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media ${breakpoints.md} {
    margin: 8px;
    padding: 12px;
    background-color: #fff;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.2);
  }
`

export const Text = styled.div`
  font-family: Montserrat, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: #757575;
  padding-top: 4px;
`
