import React from 'react'
import T from 'prop-types'

import HeaderDesktopMenuReduced from './_components/HeaderDesktopMenuReduced'
import HeaderDesktopMenu from './_components/HeaderDesktopMenu'
import HeaderMobileMenu from './_components/HeaderMobileMenu'

function Header({ logo, reduced = false }) {
  return (
    <>
      {reduced ? (
        <HeaderDesktopMenuReduced logo={logo} />
      ) : (
        <HeaderDesktopMenu logo={logo} />
      )}
      <HeaderMobileMenu logo={logo} />
    </>
  )
}

Header.propTypes = {
  reduced: T.bool,
  logo: T.shape({}),
}

export default Header
