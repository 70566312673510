import React from 'react'
import classnames from 'classnames'
import camelCase from 'camelcase'
import * as S from './Tag.styles'
import T from 'prop-types'

function Tag({ clickable, tag, outlined, big, onClick }) {
  return (
    <S.TagGrid item component="li" xs="auto">
      <S.TagChip
        onClick={onClick}
        clickable={clickable}
        className={classnames(camelCase(tag.slug), { outlined }, { big })}
        label={tag.name}
      />
    </S.TagGrid>
  )
}

Tag.propTypes = {
  clickable: T.bool,
  tag: T.shape({
    slug: T.string,
    name: T.string,
  }),
  outlined: T.bool,
  big: T.bool,
  onClick: T.func,
}

export default Tag
