import styled from 'styled-components'
import tagColors from 'utils/tagColors'
import { breakpoints } from 'utils/breakpoints'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'

export const TagGrid = styled(Grid)`
  list-style: none;
`

export const TagChip = styled(Chip)`
  font-family: Annie Use Your Telescope, cursive;
  font-size: 18px;
  font-weight: 700;
  border: 1px solid;
  margin-bottom: 4px;
  color: #fff;

  &:not(:first-of-type) {
    margin-left: 4px;
  }

  &.big {
    height: 40px;
    font-size: 20px;
    padding: 8px 16px;
  }

  ${tagColors};

  @media ${breakpoints.sm} {
    &.big {
      font-size: 24px;
      padding: 8px 32px;
    }
  }
`
