import { useState, useEffect } from 'react'

import { debounce } from 'throttle-debounce'

const getScrollTop = () => {
  if (typeof window == 'undefined') {
    return 0
  }
  return (
    window.pageYOffset ||
    (document.documentElement || document.body.parentNode || document.body)
      .scrollTop
  )
}

const getDocumentHeight = () => {
  if (typeof window == 'undefined') {
    return 0
  }
  const body = document.body
  const html = document.documentElement
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
}

export default function useScrollInfo() {
  const [documentHeight, setDocumentHeight] = useState(getDocumentHeight())
  const [scrolledHeight, setScrolledHeight] = useState(getScrollTop())

  useEffect(() => {
    const scrollListener = debounce(10, () => {
      setDocumentHeight(getDocumentHeight())
      setScrolledHeight(getScrollTop())
    })

    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return {
    scrolledHeight,
    documentHeight,
  }
}
