import React from 'react'
import T from 'prop-types'

import Logo from 'components/Logo'

import * as S from './HeaderDesktopMenu.styles'

function HeaderDesktopMenu({ logo }) {
  return (
    <S.Wrapper>
      <Logo size="big" logo={logo} />
    </S.Wrapper>
  )
}

HeaderDesktopMenu.propTypes = {
  logo: T.shape({}),
}

export default HeaderDesktopMenu
