import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import T from 'prop-types'

function Logo({ logo, size = 'default' }) {
  const image = getImage(logo)
  return (
    <Link to="/">
      <div
        style={{
          width: `${{ small: 150, default: 250, big: 400 }[size]}px`,
          margin: 'auto',
        }}
      >
        <GatsbyImage image={image} alt="Imaginarium de Pomme logo" />
      </div>
    </Link>
  )
}

Logo.propTypes = {
  logo: T.shape({}),
  size: T.oneOf(['small', 'default', 'big']),
}

export default Logo
