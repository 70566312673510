import React from 'react'
import * as S from './TagList.styles'
import T from 'prop-types'

function TagList({ className, children, center }) {
  return (
    <S.TagListGrid
      className={className}
      container
      component="ul"
      alignContent="stretch"
      alignItems="stretch"
      justify={center ? 'center' : 'flex-start'}
      spacing={1}
    >
      {children}
    </S.TagListGrid>
  )
}

TagList.propTypes = {
  className: T.string,
  children: T.arrayOf(T.node),
  center: T.bool,
}

export default TagList
