import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as S from './Menu.styles'

function Menu() {
  return (
    <S.Wrapper>
      <S.GastbyLink to="/">
        <StaticImage
          src="./_assets/home-pink.png"
          width={28}
          height={28}
          alt="Home logo - Imaginarium de Pomme"
          placeholder="blurred"
        />
        <S.Text>Accueil</S.Text>
      </S.GastbyLink>
      <S.ALink
        rel="noreferrer"
        target="_blank"
        href="https://www.instagram.com/imaginariumdepomme"
      >
        <StaticImage
          src="./_assets/chat-pink.png"
          width={28}
          height={28}
          alt="Message logo - Imaginarium de Pomme"
          placeholder="blurred"
        />
        <S.Text>Contact</S.Text>
      </S.ALink>
    </S.Wrapper>
  )
}

export default Menu
