import React from 'react'

import ScrollToTop from 'components/ScrollToTop'
import * as S from './Layout.styles'
import T from 'prop-types'

import Menu from './_components/Menu'
import Header from './_components/Header'

function Layout({ children, logo, fluid, reducedHeader }) {
  return (
    <S.Wrapper className={fluid}>
      <Header logo={logo} reduced={reducedHeader} />
      <main>{children}</main>
      <Menu />
      <ScrollToTop />
    </S.Wrapper>
  )
}

Layout.propTypes = {
  children: T.node,
  logo: T.shape({}),
  fluid: T.bool,
  reducedHeader: T.bool,
}

export default Layout
