import styled, { keyframes } from 'styled-components'
import IconButton from '@mui/material/IconButton'

import { breakpoints } from 'utils/breakpoints'

export const slideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`

export const IconBtn = styled(IconButton)`
  background-color: #fff;
  position: fixed;
  bottom: 16px;
  right: 16px;
  animation: ${slideUp} 300ms ease-in-out;
  box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.2);
  visibility: hidden;

  @media ${breakpoints.sm} {
    visibility: visible;
  }
`
