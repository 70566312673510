import styled from 'styled-components'
import { breakpoints } from 'utils/breakpoints'

export const Wrapper = styled.div`
  display: none;
  justify-content: center;
  margin: 0;
  width: 100%;
  max-width: 100%;

  @media ${breakpoints.md} {
    display: flex;
  }
`
